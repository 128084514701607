body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* direction: rtl; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.SwitchBoxSelect{ width:100%;}
.SwitchBoxSelect .lc-select-head{ padding:2px;}

.needAdmin{ background-color: red;}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  width: 10px;
  background: #e2e5eb;
  /* box-shadow: 0 1px 0 rgba(255,255,255,0.79),2px 2px 4px rgba(130,143,134,0.31) inset; */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  min-height: 28px;
  width:10px !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  /* border: 1px solid #fff; */
  background:#fff;
  /* box-shadow: 0 5px 5px rgba(0,0,0,0.2), 0px 0px 8px rgba(255,255,255,1) inset; */
  /* background-image: linear-gradient(to top,#cccccc, #f1f1f1); */
}
::-webkit-scrollbar-thumb:hover {
}
::-webkit-scrollbar-thumb:window-inactive {
}

.icon-read{
  width: 23px; height: 11px; display: inline-block; overflow: hidden;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAALCAMAAACj4OZyAAAAV1BMVEVMaXFYuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1YuE1IIF0tAAAAHHRSTlMAGTgN2+cJpxR09I2vIGeWy16CY0Eq3sLVyrovJvGszQAAAGVJREFUeNpVyVcSgCAMRdEgIGDv9e1/nSqJI96Pk0lC/0LXiL96wIlpAXBKTCqBVkzKHTCwvJf1M/QKjCyfK9iZ6DQoJtLbI6cMFj1b2Ix89M3fjwKmpib6pTbg8BSiadleefHtAovHB0Y82vC6AAAAAElFTkSuQmCC') no-repeat 0 0;
  background-size: 100% 100%;
  margin: 0 3px;
  vertical-align: middle;
}
.icon-unread{
  width: 22px; height: 22px; display: inline-block; overflow: hidden;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAYFBMVEVMaXH5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX5mEX////5n1H+6tr+5tD/+PP6rWr7s3f93cH6pl78z6j+8eb91rX7wY+F4O3CAAAAEnRSTlMA0/qzCB7z42sRefB6JLLK19nja9GNAAAApklEQVR42nWR2xKDIAxEw0UBL7VNENRe/P+/7EAppTKel8zsQzabhQ9GC8Xbq9AGfsiux0Q/SEg0DAtYk1SFf6ioywkPTGFPhxUdgEluK5HLvgY0RhZPs8UvGi5x3j09LWYExDPsTH4pjwEexkyZ6MChDfKjkm8YeBHt5ZJkiY5oLS01Ym2qcxy7uc3mOGfhQbKjyuLLx+Njx5MaEnKoSisq5ipX/AZAfxp/+XnMRgAAAABJRU5ErkJggg==') no-repeat 0 0;
  background-size: 100% 100%;
  margin: 0 3px;
  vertical-align: middle;
}
.icon-rengong-small{
  width: 25px; height: 24px; display: inline-block; overflow: hidden;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAMAAAA4a6b0AAAAh1BMVEVMaXH0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1M/nhoOAAAALHRSTlMA/Aq/00TM7CF3swab2XuIMOPerTY7HI0qvaZp9wNIxFZilKFaD29lGUyGblmnmloAAAD7SURBVHjabdDZcoJAEIXhX7Zh0YRNQHA3akLO+z9fhgtEjN9VV/Xp7qnhobqf23MX8qrcSCY3Cx3WzCzlHRnsAxU8WcmH5hR1KZSLLJka2x33lQbZDYyZViXEGrmgGAaNfDaaBKRaMwgybnrWE+RYVxtYaGYXqgKOYq+5E/qF5OBx1lyL6zl4qik0t+FDK6SeVpou2SomkpCWNqE8jFwjc/gIA6mmloYfI7RJH5zQgXQrlbgq4KTGFtLnFXDsuDwSRVh9SihrGy8LI6shEqO9Jl+gCw9lML54DbUSnhwvXrZq/W/4sWMvdtCZuFPPf5WsiDecXPJ5q4rGxh8Q3Cwr5inokgAAAABJRU5ErkJggg==') no-repeat 0 0;
  background-size: 100% 100%;
  margin: 0 3px;
  vertical-align: middle;
}

.list-box-bg{ 
  min-height:calc(100vh - 130px);
  direction: initial;
  background:#f5f6f8;
  border-radius: 8px;
  padding:5px;
  box-sizing: border-box;
  position: absolute;
  top:25px; left:15px;
  z-index:0;
  width:345px;
}
.list-numbers{ padding-left: 10px;
  position: fixed;
  top: 92px;
  left: 10px;
  text-align: center;
  width: 350px;
  color: rgb(245, 166, 35);
  font-size: 13px;}
.list{ 
  /* height: 85px; */
  position: relative;
  box-sizing: border-box;
  /* background: #ffc1c7; */
  border-radius: 16px;
  margin-bottom:10px;
  padding: 0 20px 0 0;
}
.list .list-box{  width:335px;cursor: pointer;  position: relative; z-index:2;
  display:flex;
  box-sizing: border-box; 
   /* height:85px; */
  background-color: #ffffff;
	border-radius: 16px;
  padding:14px 10px 16px 12px; transition: all 0.3s;}
  .list-del{ display:none; color:#bbbcc0;  cursor: pointer; vertical-align: middle; font-size:18px; padding:0 0 0 5px;}
  /* .list-del{ cursor: pointer; width:90px; padding:0 0; height:85px; border-radius: 16px; position:absolute; z-index:1; top:0; right:20px; background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAAWlBMVEVMaXH0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1MFI8pUAAAAHXRSTlMAmvDRVeoxyKXGqdi9d8qLQAj3Xg6ftR8MHTewT8k7SrYAAAB2SURBVHjaVc7ZDoMwDETRIaEhTsLWBejC//9mM5ZazH2yjjSSocnOCo5CywK0xYtIbFiql3+jyzJ0/wYvwPZI8VdKXE67ra+ynuRTZXZWXqhlK09KMeBmSjSSwUYjRaU3ElVaI6PKdHqQ3a+HBGhB3IW5fAPwBd1fElx6puIvAAAAAElFTkSuQmCC') no-repeat 50px center #ffc1c7; } */
  .list:hover{ overflow:hidden;}
  .list:hover .list-box{ box-shadow: 0 0 30px rgba(0,0,0,0.05);}
  /* .list:hover .list-box{box-shadow: 0 0 10px rgba(0,0,0,0.05); margin-left:-60px;overflow:hidden;} */
.list:hover .list-del{ display: block; }
.list:hover .list-name-time{ display: none; }
.list-img{ width:55px; height:55px; border-radius: 55px; background-color: #d9d9d9; position: relative;}
.list-img img{ width:55px; height:55px;}
.list-img .active{ width:12px; height:12px;border-radius: 12px; overflow:hidden; display:block; position: absolute; bottom:0; right:4px; background:#aeaeae;}
.list-img .online{ background:#56d7d2;}

.list-select .list-del{color:#fff !important;}

.list-name{ box-sizing: border-box; flex-grow: 1; padding:0 0 0 10px; display: flex; flex-direction: column; justify-content: space-between;}
p{margin-block-start: 0;  margin-block-end: 0; }
.list-name .list-name-item{ padding:0; height:22px; align-items: center; display: flex; justify-content: space-between;  }
.list-name .list-name-value{ padding:0;font-size: 14px;color: #112349; font-weight: bold; display:flex; align-items: center; text-align: left;}
.list-name .list-name-time{ padding:0; font-size:12px; color:#c2c2c2;}
.list-name .list-name-msg{ line-height:16px;  width:190px; padding:0 0 5px 0;font-size: 13px; color: #858585;white-space:nowrap; overflow: hidden; text-overflow: ellipsis;}
.list-name .list-name-msg-num{ padding:0;min-width: 24px;	height: 24px; border-radius: 24px; overflow: hidden; text-align: center; padding:0 5px; box-sizing: border-box;	background-color: #f44f53; font-size: 16px; font-weight: bold; color:#fff;}
.list-name .list-name-msg-num-bot{ padding:0;min-width: 24px;	height: 24px; border-radius: 24px; overflow: hidden; text-align: center; padding:0 5px; box-sizing: border-box;	background-color: #1bd372; font-size: 16px; font-weight: bold; color:#fff;}

.list .list-rengong{ position: absolute; left:75px; bottom:8px; width: 130px; z-index:10; cursor: default;
	height: 35px;  font-size: 16px; color:#420002;
	background-color: #f8e2e4;
	border-radius: 18px; display:block; padding:6px 10px; box-sizing: border-box;
}
.list .list-rengonging{position: absolute; left:5px; top:5px; z-index:10;}
.list .list-rengonging .icon-msg-rengong{ width:30px; height:30px;}
.ani-shake{  animation: shake 0.5s infinite;}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
  

.list .list-rengong .icon-rengong-small { margin-right:5px;}

.list-select .list-box{ background-image: linear-gradient(
  #3598db, 
  #3598db), 
linear-gradient(
  #c3f0ed, 
  #c3f0ed);
}
.list-select .list-name .list-name-time,.list-select .list-name .list-name-msg{ color:#fff !important;}
.list-select  .list-name .list-name-msg-num { color:#fff !important;}
.list-select::after{ content: ''; display: block; width:0; height: 0; z-index:10;  position: absolute; top:50%; right: 0;
  border-top: 15px solid transparent;
  border-right: 0 solid transparent;
  border-left: 23px solid #3598db;
  border-bottom: 15px solid transparent;margin-top: -12px;transition: all 0.3s;}
/* .list-select:hover::after{right: 60px; } */

.list-up90 .list-box{ border: 10px solid #f4a44f;  }
.list-up90::after{ border-left: 23px solid #f4a44f !important;}
/* .list-top .list-name .list-name-time,.list-top  .list-name .list-name-msg{ color:#fff !important;}
.list-top  .list-name .list-name-msg-num { color:#fff !important;} */

.list-up30 .list-box{ border: 10px solid #edaeae; }
.list-up30::after{ border-left: 23px solid #edaeae !important;}
/* .list-up30 .list-name .list-name-time,.list-up30  .list-name .list-name-msg{ color:#fff !important;}
.list-up30  .list-name .list-name-msg-num { color:#fff !important;} */


.list-up40 .list-box{ border: 10px solid #6d0505; }
.list-up40::after{ border-left: 23px solid #6d0505 !important;}

.list-up50 .list-box{ border: 10px solid #04aa33; }
.list-up50::after{ border-left: 23px solid #04aa33 !important;}

/* .list-up40 .list-name .list-name-time,.list-up40  .list-name .list-name-msg{ color:#fff !important;}
.list-up40  .list-name .list-name-msg-num { color:#fff !important;} */





.send-robot{color:#b1bac3; font-size:12px; text-align: center; position: relative; margin-right:12px; }
.send-robot .icon-robot{ width:60px; height:60px; display:block; margin:0 auto;}
.send-robot .robot-on{ background-size: 100% 100%; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAxlBMVEVMaXEhxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl3///8+znP7/vyU3qnU8t3u+/P2/fkyy2pSznpT04Ki4rRG0Hi768nq+e7f9uY5zG8kx2Dx/PXM8tqH4ahM0nxc1oj1/PbG8NR22Zah57vX9eL4/fqt6sRl140syWWG2p48ymzr+vCY5bRv2pbm+e3rqXLPAAAAHHRSTlMAykEGp+8U/MMOPrRySHwc89GUVb4Z5MauVt+WMJunbgAAAp5JREFUeNqll2l3qjAQhkOLAm61tbXbvIpbVbTu1qt2/f9/6mJASzbrwecckS8PJDPDJGF6vGLJvq+WicpVyy4VPXYyV4V7hwSc+8LVSWouH5uSn8/9qV64ZMS9OKre2XQU+87sVjL0B5mKQb28oRO4udRm55ZOws2qbtaiE7lWbE/j9ppAUNPYnjRf3ZgXGDfQJ5Vbcd7aWP1gOcFKGzUhR6TjCyET0vGYKGZ9fl/7wNqQ799SfyA9Q2BLeh4O9UwGpkCNDOzr3CUDNaBtrJXIzanD7dU4G2AW3fXUh+S4nJfjtJ6jvqNVB8LLDjTfZDnP69IhkTW0yLazq9ICibTr0DKXA1/QhKtmkEdtNWSeI8staGnKsuOxIiWZfdOQy6vNJB5BfbIZcHlL3zNKUmQlSgIMX7k8JRqD0yeacZl6qFOSErMleUpc/qLhDziDNr1F8gwQ2yGzJLkXyViNDpNdIZJf0KIkFqvqZRVVrrJyernMyCR/fk9fQqbLkUkmszwRGkokQ0y0NOz14rUdy4t2XK7jWN5uF2J9Z1iVTBX2OW6EjJumCqMnZinyP4gYZYvZauPS01Jkm5WUnjmAlgbJlFiRZF5G0PDZI5ki/yTT4Xi8GaTD5W0oJYWoAabCyUatNxV5Q9MnfwAEXQqJU9SAr2/6asj6v5kF4Gtl17DQ+UCXqMuvAOYfOvnCsMQG6EfvD3byAn2N/GBY3D+Adwp5Bz4I2Myx5LJhcWePlKADdChkufsPfz4CRX5kCZ4FWXhzJzS7kvxs3krN93MeEJc7QCDIrrSFzF7Tge4+2j6X+S18cQtothv7hSaWKeCy4Jo3rt0AGPh0kJcJ2c2es2VOvVkvV845Jpx9QDn/aHT+ocx8HLSeMo6TeTpyHPwPvf5BtlJn7b4AAAAASUVORK5CYII=') no-repeat 0 0;}
.send-robot .robot-off{ background-size: 100% 100%; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAhFBMVEVMaXG6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMD////Dxcn9/f3+/v/v7/DOz9LAwsb6+vvGyMvc3d/y8/P39/fKzM/q6+zT1dfY2dzh4uTm5+i+v8MeMjqHAAAAGHRSTlMAp++VP80VxPwPVQa0cnzzvuQaQ65I3x660PQrAAACW0lEQVR42qWX65KqMAyAiy4CXnC94CYotFwExPd/v+PSHp3WxHXw+6UM3zRtQ5oKmiiJvWCzBlhvfC9OIvE221UQgkUYrH7eUr+WxnT85dffagAswWt968FLvC3vzifwB5M5oy6m8AbTBbk73/AWAbFvMx/exJ89jUu4hUKsU8KOnPlSMWeYVdjAM9/2vMm1qrHtsCJXzdojoLjgjRIo5g93y+xvj9gz+/3Ilj3QSMQr0Ozv+QwMBWIKDDsjB8CQIko2V7iBZZEO5Ii5/lVIcNGf2BJsrr3C48AJ8ahBdXHl5ZCX4dMak7h2+JulKzfmEy0rd+FXxHKlR0aWz0sWhWPlMBKJ9SBvjVzlpQn/1OWVCbs9Wy8nIrb+I0oY5AIg03IDkA8yFHiyXo6F58iFlkuQtZYrCRct547sCZ+WsVJoUBVq+YxHuyiIDSG7MPJGrMfLawGcrNrifKNoFScDL3dWQdEy2hvthN03II2cmXSUmZGv18zO74nYAJdhKqtuZIrLMDgIH8amJ/jCe5KR5ngFB0/ERLkmqcAlFgm4nBUZdQEuif4kRxFGuhiMItBlaBwrXQBHEc506R3Fki76eYOIpYT/xSQHHKCKvrtkHQ7U6Us5EJqdm2GNhFxh8yuX5mPLwWFHHrE91mb89IW8pw/3Ci/mbG55ebKl24oaOyN0Zs6g55zBgznT0NTWyLQ85Vqp5j5nyYUdLLgGMB8OiVZhD5RsWkDOLu8nBSEbl29c2wxRDRYlB9EnLfPoZn09//iaMP6C8vnViOdnxKXMvQ76h0kYTg4vroP/AMtnELYf9P8vAAAAAElFTkSuQmCC') no-repeat 0 0;}
.send-robot p,.send-btn p{ position: absolute; bottom:-15px; transform: translate(-50%,0); left:50%; color:#b1bac3; font-size:12px; }

.top-on{ background-size: 100% 100%; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAaVBMVEVMaXG6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMD////e3+HCw8f8/f3w8PHV1tj6+vvOz9LMztH39/jq6+zY2dza3N7Jy86+wMRzI5G0AAAAE3RSTlMAD7Kn8UDNGMT8VQaVSHxyvuTfNkpi0AAAAeVJREFUeNqll+myojAQRsMiJCogfk2UMCz6/g85E29qKKg0csP5fyp0d9GL8BMVZSLrFEjrLCmLSOwmr6TCAiWrfJd6ihU8qPj0XZVgkdt6nmCTJOfdc4ovpGdGvVyxg+vFW50bdiEjT7gZdpLlazfyuKwdreK94RfclnFf8SuuixphyWgeC0yDJefZzdf1fdIKs673nLQYK/TULJg0VsTCcUIAJydLcLzf4JDfHn4Z8/rydMy69A/GdlHnCn70w8oPDT/KJrziXOOqxNmVTRfvDg8aeFsKESnWbd9Erxdrq0gUfrcnamzGWrREvd8uRMm76KyMkbNLkcDDj4s/Vv6x4SERGTw0psEsY+wbeMhEDR9vzDJLLVIwfJdTgXAZR2R17LPrcPkusnA5E0m4nIgSPB3RCJ5SFODR5vECT2F/yUBUZJtBINK1oSAq1wBDULlrvSHE/qbfDUQ06f8DrwN98M4biQUjfej1piy9g04TDRqdocHKEyxEHTPoVlEP1Lv39YYc+4f7kxr3AS0vp7l/rehpdMLoYgbI8sTMmVlo+vllVr4yq9SemOWFWwA7snluuWy7FZCzJ/rw1IxsXX5xbZ9ExlpeWUYHV+bwZT38TDh8oBw/jY4fZfw5mN1TpdL7xjn4F2bu1bBVUrgbAAAAAElFTkSuQmCC') no-repeat 0 0;}
.top-off{ background-size: 100% 100%; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAk1BMVEVMaXEhxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl0hxl3////1/fiU3qmP465Qz3rp+e46zG523Jtb1YjU8t2i4rTw/PSY5bS768k1y2yA36Ls+vFT04Jz2pbF8NWG2p4ryWXf9ube9+c/znNk1476/vsKuFZWAAAAFXRSTlMAG7Knw8kU/PE/DgZVlXxIctG+5N9p5or/AAACGklEQVR42qXX63KqMBSG4QDhICpY9VuwwVbwULXadt//1e00o8wOZomFd8bDn2dIdCYHYS9OE89fREC08L0kjcXTOTM/hFHoz5yn6MhtpOHdUTeVYJOPuePhYZ7D20mEjqIJQ8dTPNF0bLPxHE8lg3sb+HiylzsdWyyr49Z85/hFc3PeU/yqqfEfwWxfZ0b1BmYT0eREMDtRqxpmkdNgF612f1p9oZV7syP0aHTFElznM7hk14MPdX3oeLQLpoJUBZj0rIMQ9lYZqbIV7IWBwjPOvpPundMzhSVvT3/pxGspRGwf9U7Z446oOCi9s487FqndlkQbKHjEnqi061Qk1jFrizeiV2htHXkiPFj61BYaa/0JS57wYWlTb9BgpcsNLPliAVtnNJhvISIwdeNIoD/GEBwOG/aiP14Kvz/2hdcfeyIB3zfRHnyJSMH3VWcH8KUiDtGzMBZC9sVSL0M9m7ELYHdhwC693bncop9fiMoKKqI1VGvKmf1GotWWdGuNKbdiyWx0OVEFVPpdL/s2PGK22JK20M8vf/CJthbsMpv7iugDqg+ilcLfGR3vcOQwx4riursdfz7VK6fyDk+4A01hPrlQsmrhKX+Uym5zvkDjgqg0sBwLo+AFTdXt18411l8pN4+AvF6TbosrRqmxYfmDa1USXXI0+PgflsGQI/OAw/qQa8LwC8rwq9HwSxl/HVxGYRgtH1wH/wHJte4On/QqfAAAAABJRU5ErkJggg==') no-repeat 0 0;}


.icon-smile{ width:36px; height:37px; display:block; margin:0 auto; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAlCAMAAAAdgrsPAAAAgVBMVEVMaXG6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMA0RIi5AAAAKnRSTlMAawwfTijLrLYR2S/ewZP6upmxGeJf5/OeU4ujNvf9ZkhYpj/tgwEGenG76vcFAAABtklEQVR42o2U2XqCMBCFh52wg7KIoiBg7Xn/B2yHFBqE+vW/CZOQYeZwElIR5pjZnmdnoylon74asDBUPW2xMjBBmGVhAMax6IVPnnZv1oODh3VzOdZoxZl3Xkjh4gCoSCEDyo5e6EogW+XxDNpgeMB5DjTA02kH3VvqOgDNgXY5NIBcSoCW/sAEXB59Hv/EBXwicgBj6jmthVLOGFlT8YDzHcUy0QXAcXlLeEDsy1SNTh1gkkwIfCoNc4pvTkBHKeKp/TtP37YviRgRhbCJ8WNVLXEEV8zYCCmYRbXqp6KoqFOfJu4IqEBEb4lQ0ICa3lJjoOE/mQLc6S1cE3f3Du6OdRKTaGFOK/JwEllcEbHindwA8+X/8yd4/cSKJhy00viq5K00Uqz/uiAHkJgf/PhhJgDyHxfcVT+dSjZCFkXuEUB5mh35YwbICeOMmWtlyI1zhgMQyymynklQlkHytGRsXGeP002120OIx3IOjop7KiCwaIMVrM6wAzQ5vZA37DuFFIDbk0LvAkhphXYFEGq+1MnXQm5R2xTggCls17WL/fuJ6dMCC0V6oX30dnT4znTGVieFL1pcNnrOYT10AAAAAElFTkSuQmCC') no-repeat 0 0;background-size: 100% 100%;}
.icon-file{ width:32px; height:28px; display:block; margin:0 auto;  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAcCAMAAAA3HE0QAAAAY1BMVEVMaXG6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMBoJqIMAAAAIHRSTlMA4jvHsqK9UUooffYS+4LseAozmHIYrENMZo7X0CBYBM2iTy0AAADtSURBVHjahdPXjoQwDAVQhxQIvQ9lyv3/r9xgaaMRjMUVD458RCgOkTcVhFTGh34ByCk8OSBf0p9ZcsAR0JKYFgggkUHCIDvKR72WV5BFYAGoRAYt0ISrFMEOUy7AWwQKG5XAKgILzDvwOoPlH0wKISNxPlk9O+3s2JOPb1Fu2qTc7nLGHFNHEJMe7cbZweoCwAXUAEzacV2uzxPgvvr2lkEf12/gOdFXegbauHn0YTkVUN3lO8QnTmjgu13Bbq0+yKYw0AnEefB5cxj/Ezy4fjlAi/+CY1Wg0hacThi5m6F1qOSxr+BuD87t0fsDQ1wp5jo5UXQAAAAASUVORK5CYII=') no-repeat 0 0;background-size: 100% 100%;}
.icon-reply{ width:35px; height:35px; display:block; margin:0 auto;  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAclBMVEVMaXG6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMC6vMDaDfMzAAAAJXRSTlMAqLWHj/Niax8NFVX9zzRDOdc+3Zsld+S/LfmUCMdHTgHrWxnv4TjKsgAAAYBJREFUeNqF09muqlAURNGilx4EAUHsjvP/f/GGoBcQlfFESGUVe+2gpawy+uLh3PKmcw/6July5vrq72PMYFD3RtmVZnwDaK1Ab64dQOMdrxqdMisHHE8LxxwoEy1dGsC4anIGmkRr9xriqX4PWPrIN6E4abSL4K5vSog1KsDVdwbYGlRg6ZcYEkkB5PppN5ZbkOk3GxKpJtYGP8JQNp3kb/fO18jkporouVAr4p2TSgOPVgaFRg0rr7KEVjmNRr7rvQs1CkAtpjadQDWNNvkgk1ibDkSycU7aEtIqhFQvrj2X6aXEkd9i6OnIkl5u9JIJgUZB78w8uqkZV8qYRsqfmw18jBfJWb904D3X3v7puzvE/5/qQN9cgNccC+pEn7nMv8wGvJPW/BIINamAvVa8BzjL9946mFgFYO60cHz+YqHtnbNkf/HKHKBw9SZlCB5M5vohtg5eZTGpzeqoD1JarweMw24fhpf94arPUiIgP2tLCkSVtqVDq7TtUoT66R+kuDN68jOS3wAAAABJRU5ErkJggg==') no-repeat 0 0; background-size: 100% 100%;}
.icon-send{ width:59px; height:59px; display:block; margin:0 auto;  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAAA7CAMAAADy+wKBAAAAXVBMVEVMaXH///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8T8+gCAAAAHnRSTlMAcObbbL1LRLHz0SnDkzUSCv4+XFLiAiGjZ4d7nstkGv+iAAAAyUlEQVR42u3Uxw7DIAyAYUYW2Xu0jd//MasuERokbA49VHz3XxywzYIg+CuRUNO18UoTeBBedQwvgs8e7YfkLbHloMmN1i4dHOQDKc4BvOsLfCkTdFvDSTki21nAWZzi4hvYZKh6BLuscLftDnYKEc/pW8+V+TKjmIxWIkaL8+hpWzujrZxpo8BOuvdyAyt1QaxVBhZdtTC3VtnKAn90TKsuiSO51p5fChm+ZDV5A7TqWPaMZNc7mzCiUpdkvb5RdOOu4oEFQfALd9zQM+4MjjJOAAAAAElFTkSuQmCC') no-repeat 0 0; background-size: 100% 100%;}
.icon-send-gray{ width:59px; height:59px; display:block; margin:0 auto;  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAAA7CAMAAADy+wKBAAAATlBMVEVMaXG7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMC7vMCOsTxcAAAAGXRSTlMAS8XPD/O9bkSxY9splzXmU/6iBj6J4iF7HM0BFgAAAMFJREFUeNrt1MsOgyAQheHjBRBRwVvtvP+LNk1NWgsJA4suGr79HxZkDoqi+CvKitu9RY6KnmxWLenFqjWnPZnkWtGb6ZHkmOlDk1Y3RNn1Tl/qClwTeWoHJks+2YFloRDNqh2F6QFRa0NhghGjO21KXF9GitulNYg61Klf5ks7IqYVFGZaxPQUJPZ4Ck0B83ggbhWhcmCOjmcZwDN65ZT5paT5JSbvAvJOX2/InBxZIZH0Sr7N3yg+VwvpUBTFLzwAMaUrUiI08MkAAAAASUVORK5CYII=') no-repeat 0 0; background-size: 100% 100%;}

.chat-message{outline:0;}
.send-active:active{ background: #2b8aca !important;}

.icon-msg-robot{ width:43px; height:43px; display:block; margin:0 auto;  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAMAAADWg4HyAAAAh1BMVEVMaXE1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNs1mNvD5f2XzfNBn96Lx/BXq+TA5P2j1PaRyvE9nN10uuu94vxKo+Cv2vmc0PRvuOm23/pksuc3mtx7vuyHxO+p1/ddreQDzPVsAAAAFnRSTlMAXegMuBXw5ANz4EJkSBounT14nDWeRpnMJgAAAZtJREFUeNqdlWlvgzAMQNP1oOHqsS0mkJYCAVrW///7BgUSnHJIfV+QpSfkOLZD3vAdl56p6/hknr13tFmPffT2k+aaWgxj0fW4uvpi73ytRsztho2z2ZrqwWJTWAesnnZsQCmDiGl2J/RXpEYAUPChfBjkihMIoOaB0tA5G8e6Nm6OD6iKxTBpAXC5M0RXuvVbXZ8gYrPO7aVQZpJCwkzoqwcspEU1NxBl802Hx2t6wxuaEhDhIBWvdr91yAsAIQSIJBENABemONaurcMKsijmirhMoNQtSojPNBe4mZWudOATB7k5dkMIdeAQt/ceY/9t3Eq2gauqC8mEGwP0FT73bta5d97Vr3eTzj0TarhxlrzO/hCh4VLiGi7v2lFCZrguccwcovzVYjxPDddR9YVi6Wy+ujeQE+49a11b90MaT9WXt+32q/ps+d483L8BSKTGGdxQ/xI6HPa/a6C4FiC4mgtz3nIBiCJC84bn+BnKQCGrVM+x3g9LbNDemcXaon02x+4H78k59fTJ/l3e65+/F8vv0Ofv2/K7+Q+MS32OmJ0HWAAAAABJRU5ErkJggg==') no-repeat 0 0; background-size: 100% 100%;}
.icon-msg-rengong{ width:43px; height:43px; display:block; margin:0 auto;  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAMAAADWg4HyAAAAsVBMVEVMaXH0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P////0T1P2ZWj1Vlr3gIP+8vL80tP5oaP7vL794eL7xMX+6+v2bnH2dHf8ysv0Ulb+5ub6rK74i473en35pKb//Pz81tf6sbL4lpj93t//+fn4k5X7trj93N35nJ71XWH5mJr+WZc8AAAAGnRSTlMADAPgFfAB5HO4GkJILp1d53Q9Y2V4XOg1nvAxjWUAAAHmSURBVHjapZXpUupAFIRBkYRFEBX0docsJGRjEZBN3//B7oB3PJlLkCr8fk1luk7N9HTOqZzQbD3Zffup1az8zE27c0vNbad9c1ZZtS2aWHa1VFnvPvCUl279VFprsJxG7X/pvcVzWPem9PGO57l7NKqKtFRcqFyz+DNW7dsB81rOahttV455Qe3GgAUSH/ByD1jMWGDw7wmKvo4QTnnA3SGi8PD1KDaFHC4Zb4JVTCZv46Hs2McMWAXpOuMqx4HxnvS8wvUO2WgXD5AxhcYnkcpmW2k71MSY0YewO37RdJRWQrgbc48iE/VJIqqiTc0QM8IkcyA+NystaqagC5MNsaemVXnWy2wRcguTiH747duzuPuOJSOY+JwjF4f7eglMDto3aNQqZQBoQV/qAiPOgdwJfA/eYu7sgCWXorXlvCO801HVZuTQGZLJGkjoI5Lzig8bxPQBTD6o5HMAITME4kPBX05iOlCs023qQREzAMVf/W4aF8KUxEjeTedBSHbar4RcIqPmj+RMmI7CcRi5H6SLqZkzya+QkZ9e+omJkV9FjyU4UAQUbON/MxjmgGv+b0e6pYWDopQD6Q+XaNSv6DuX+9nrNX3ycv+9qq/LvHi5OC+Eau9kDvWqv59vMjd7/V7Z3PwL5ViYdn0h/foAAAAASUVORK5CYII=') no-repeat 0 0; background-size: 100% 100%;}



.ReplyLiBox:hover{ background: f2f2f2; cursor: pointer;}

.fanyi-pop{ position: absolute; top:0; left:150px; color: #000; z-index: 1000; }
.fanyi-pop .SwitchBoxSelect{ width:170px;}
.point{ cursor: pointer;}

#root{ min-width: 900px;}

.rengong-ts{ height: 34px; background-color: #ffc1c7; color: #f44f53; font-size: 14px; text-align: center;
	border-radius: 17px;   line-height: 34px; margin:5px 28px;
  /* position: absolute; left: -17px;  top: -58px; width: calc(100vw - 400px); */
 }
.lc-form{ position: relative; z-index:900; padding:10px 0 13px 0;}
.rengong-tips{ font-size:12px;text-align: center; position: absolute;
  left: 0;
  bottom:0;
  width: 100%;}
.rengong-spir{ height:84px; display:block;}
.rengong-tips-li{ display:flex; justify-content: center; align-items: center; padding:20px 40px 0; }
.rengong-tips-li .line{ flex-grow: 1; display: flex; height:0px; border-bottom:1px dashed #9d9fa9;}
.rengong-tips-li p{ padding:0 10px;}
.rengong-tips-li .icon-jinggao{margin:0 10px;}
.icon-jinggao{ width: 24px; height: 22px; display:inline-block; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAMAAADto6y6AAAAYFBMVEVMaXH0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1P0T1OLYmVQAAAAH3RSTlMAhmFFqhDasvQl+j4Xr8UM4OHKvKQDbJ/lT2l+jVnLilEj4QAAALBJREFUeNp1ztkSgkAMRNFWFtkXAVFR7///pcUQxgL1vGSqMklHXt1BFelbR1nS6UvOWTqTa68llVJa7RwJ5xJy1FaCy41ItHHgIN1u9jD+I9io8auzzMK8lMbluP0NqYydb42cl0xApU9DFYEWVxsGW3yVc6eXc7nI6bnPZciotVGTDZIeTGvWunviIRUk/mh/aEKhkKcWRdMWWjwJRawfYv43RuLytFPGjNLID/3wBr9pDetFY2P6AAAAAElFTkSuQmCC') no-repeat 0 0; background-size: 100% 100%;}

.file-li-box { display:flex; justify-content: space-between; align-items: center; padding: 0 30px; margin-bottom:10px;    max-height: 37px;
  overflow-y: auto;}
.file-li-box .file-btn{ display: flex; justify-content: space-between; align-items: center;}
.file-li-box ul{margin-block-start: 0em;  margin-block-end: 0em; padding-inline-start: 0;}
.file-li-box ul li{ font-size:12px; text-align: left; line-height: 18px;}
.lc-text-field{ margin-bottom: 0 !important;}

.reply-li-box{height:50vh; overflow-y: auto;}
.reply-li-box p{cursor:pointer; display:flex;padding: 10px 20px; background:#fff;
  justify-content: space-between; border-bottom:1px solid #f2f2f2;align-items: center;}
.reply-li-box  p:hover{background:#f6f6f6; }

.reply-li-con p{cursor:pointer; display:flex;padding: 10px 20px; background:#fff;
  justify-content: space-between; border-bottom:1px solid #f2f2f2;align-items: center; background:#f6f6f6; }

.line-fg{ background: #bbbcc0; margin-right:17px; border-radius: 10px; height:10px;}

.tips-box{flex-wrap: wrap; position: relative; min-height: 34px; background-color: #fff; color: #333; font-size: 12px; text-align: left; display:flex;
  justify-content: start; align-items: start;  overflow:hidden; 
	border-radius: 8px;   line-height: 34px; margin:5px 28px; padding:0 40px 0 0;
  /* position: absolute; left: -17px;  top: -58px; width: calc(100vw - 400px); */
 }
 .tips-box span{ display: flex; cursor: pointer; padding:0 5px; color:#fff;border-bottom: 3px solid #fff;}
 .tips-box .tips-current{ border-bottom:3px solid #000;}
 .tips-box span .tips-del{ display:none;}
 .tips-box span:hover .tips-del{ display:block;}
 .tips-box span:active{ box-shadow: 0 0 10px rgba(0,0,0,0.3) inset;font-weight:bold; }
 .tips-box .tips-add{ position: absolute; top:0; right:0; background: #88caff; color: #fff; font-size:24px; text-align:center; width:34px; height: 34px; line-height:30px; cursor: pointer; border-radius: 8px; }
 .tips-box .tips-add:hover{  background: #3598db;}
.list-tips{flex-wrap: wrap; height: inherit !important;}
 .list-tips span{ margin-bottom:2px; border-radius: 4px; overflow: hidden; margin-right:3px; color:#fff; font-size: 12px; padding:1px 3px;}


 .add-tips-box{
  width: 240px;
min-height: 100px;
bottom: 140px;
right: 0;
position: absolute;
z-index: 2000;
border-radius: 12px;
background-color: #fff;
box-shadow: 0 0 10px rgba(0,0,0,.2);
font-size:14px;
padding:10px 20px;
color:#666;
 }

 .add-tips-del{ text-align: right;    cursor: pointer;}


 .reply-li-box p .tips-del{ display:none;}
 .reply-li-box p:hover .tips-del{ display:flex;;}

 .reply-add{ position: absolute; background: #88caff; color: #fff; font-size:24px; text-align:center; width:34px; height: 34px; line-height:30px; cursor: pointer; border-radius: 8px; }
.reply-add:hover{  background: #3598db;}

.reply-close{ position: absolute; color: #8e7878; font-size:20px; text-align:center;  line-height:30px; cursor: pointer; right: 0; }
